import React from 'react';

export const Progress = () => {
    return (
        <div
            className="KW_progressContainer"
        >
            <div
                className="KW_progressBar"
            >
            </div>
        </div>
    );
}
